import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";

import Img from "gatsby-image";

export default function BlogPost({ data }) {
  const post = data.markdownRemark;
  return (
    <Layout>
      <div className=" max-w-5xl m-auto pt-0 bg-black">
        <div className="relative">
          <Img
            fluid={post.frontmatter.featuredImage.childImageSharp.fluid}
            className="absolute inset-0"
            
          />
          <h1 className="absolute inset-0 h-full flex justify-center items-center px-5 text-center font-sans font-bold text-shadow">
            {post.frontmatter.title}
          </h1>

        </div>

        <div className="relative bg-white ">
          <div
            dangerouslySetInnerHTML={{ __html: post.html }}
            class="post-content"
          />
        </div>
      </div>
    </Layout>
  );
}
export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        featuredText
        author
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
